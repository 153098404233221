<template>
  <div class="about-page">
    <pageHeader></pageHeader>

    <div class="page-banner"></div>
    <div class="page-menu">
      <div class="content-inner">
        <ul>
          <li v-for="(category, index) in categories" :key="index">
            <router-link :to="'/business/'+category.id" :class="activeCategory.id==category.id?'active':''">{{ category.name }}</router-link>
          </li>
        </ul>
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ activeCategory.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="clear"></div>
      </div>
    </div>

    <router-view />

    <pageFooter></pageFooter>

  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import pageFooter from "@/components/pageFooter";
import {getCategories} from "@/api/category";
import cookie from "vue-cookie";

export default {
  components: {pageHeader, pageFooter},
  data() {
    return {
      loading: false,
      categories:[],
      activeCategory:{
        id:0,
        name:''
      },
      role:1,
    };
  },
  mounted() {
    let cookieRole = cookie.get('user_role')
    if(cookieRole==undefined){
      this.role = cookieRole
    }
    this.getCategories()
  },
  methods: {
    getCategories(){
      getCategories({categoryId:26, userRole:this.role}).then(response => {
        this.categories = response.data
        this.getActiveCategory()
      })
    },
    getActiveCategory(){
      if(this.$route.params.id){
        this.activeCategory = this.categories.find(item=>item.id==this.$route.params.id)
      }
      else{
        this.activeCategory = this.categories[0]
      }


    },
    watchChange(){
      if(this.$route.query.role!=undefined && this.role!=this.$route.query.role){
        this.role=this.$route.query.role
        this.getCategories()
      }
      else{
        this.getActiveCategory()
      }
    }

  },
  watch:{
    $route(){
      this.watchChange()
    }
  }
};
</script>

<style lang="scss" scoped>
.page-banner{
  height: 520px;
  background-image: url("../../assets/images/banner-5.jpg");
}


</style>
